/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import {
    compact,
  filter, find, get, head, isEmpty, last, map, size,
} from 'lodash';
import { ShoppingCart } from '@material-ui/icons';
import { AppBar, Badge, Toolbar } from '@material-ui/core';
import {
  setCart, setAddresses, setProducts, setAvailableTherapists, setAnonymousCart,
} from '../../../Actions';
import { defaultErrorMessage, HotJar, isEventBooking } from '../../../constants';
import {
  addCartProduct, createAddress, createCart, getProducts, updateCart, updateCartProduct,
} from '../Shared/helpers';
import ServicesList from './ServicesList';
import AddressStep from '../AddressStep';
import GenericAuthModal from '../../Shared/GenericAuthModal';
import sideImg from '../../../Assets/Images/Menu_image.svg';
import CartDrawer from './CartDrawer';
import { decode } from '../Shared/encode';
import { STEPS } from '../Shared/constants';
import { seBookingDetails } from '../../Shared/WebAnalytics';
import FinishAddressStep from '../FinishAddressStep';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      showErrorModal: false,
      tabbedSection: 0,
      showLogin: false,
      hashValue: get(window, 'location.hash', ''),
      width: window.innerWidth,
      open: false,
      scrolledDown: false,
      showFinishAddress: false,
    };
    this.scrollableTabs = this.scrollableTabs.bind(this);
    this.authModal = this.authModal.bind(this);
    this.authCallBack = this.authCallBack.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.hasCartItems = this.hasCartItems.bind(this);
    this.addressLogic = this.addressLogic.bind(this);
    this.cartLogic = this.cartLogic.bind(this);
    this.cartProductLogic = this.cartProductLogic.bind(this);
    this.responsiveHead = this.responsiveHead.bind(this);
    this.mobileSize = this.mobileSize.bind(this);
    this.goToTimingStep = this.goToTimingStep.bind(this);
    this.cartItemsCount = this.cartItemsCount.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateScrolledFlag = this.updateScrolledFlag.bind(this);
    this.finishAddressModal = this.finishAddressModal.bind(this);
  }

  componentDidMount() {
    const self = this;
    this.props.changeBackground(null);
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.updateScrolledFlag);

    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (!loggedIn) {
      const address = get(this.props, 'booking.cart.address', {});
      let queryParam = '';
      const {
        zipCode, cityId, country, state, addressLine1, city,
      } = address;
      if (zipCode) {
        queryParam = `?zip_code=${zipCode}`;
      } else if (cityId) {
        queryParam = `?city_id=${cityId}`;
      }
      if(queryParam){
        let formatted_address = compact([addressLine1, city, `${state} ${zipCode}`, country]).join(', ');
        queryParam += `&formatted_address=${formatted_address}`;
      }
      if (!(isEmpty(address))) {
        this.fetchProducts(queryParam, address);
      }
    }
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  updateScrolledFlag() {
    const self = this;
    if (!self.state.scrolledDown
      && ((self.state.width <= 800 && window.scrollY > 0) || window.scrollY > 320)) {
      self.setState({ scrolledDown: true });
    } else if ((window.scrollY <= 320
      || (self.state.width <= 800 && window.scrollY === 0)) && self.state.scrolledDown) {
      self.setState({ scrolledDown: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.updateScrolledFlag);
  }
  fetchProducts(queryParam, address = {}) {
    this.props.setLoaderFlag(true);
    getProducts(queryParam, (resp) => {
      const products = filter(get(resp, 'data.products', []), (el) => (el.bookable || isEmpty(address)));
      this.props.setProducts({ products });
      this.props.setLoaderFlag(false);
    }, (err) => {
      this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      this.props.setLoaderFlag(false);
    });
  }

  goToTimingStep() {
    let relevantStepId = isEventBooking() ? 'REVIEW' : 'TIMING';
    const nextStepCustom = find(STEPS, (step) => (step.id === relevantStepId));
    this.props.setBookingFlowStepThroughParent(nextStepCustom);
  }

  scrollableTabs() {
    const products = get(this.props, 'products', []);
    const hashValue = this.state.hashValue;
    if (isEmpty(products)) {
      return null;
    }
    return (
      <div className={this.state.width < 800 ? 'top-sticky-80 sm-top-sticky-56 bg-primary z-index-1' : this.state.scrolledDown ? 'pl-64 fixed-top-80 bg-primary z-index-1' : 'abs-btm sm-relative w-100-perc-minus-64'}>
        <ul className={`nav nav-pills light-mode-br-transparent ${this.state.scrolledDown ? 'w-75-minus-64' : ''} sm-w-100 `}>
          {map(products, (prd, ind) => {
            const hrefVal = `#${get(prd, 'slug', '')}-services`;
            const relevantId = get(prd, 'slug', '');
            return (
              <li className={`nav-item ${(!hashValue && ind === 0) || hashValue === hrefVal ? 'br-b-blue-5 mb-minus-5' : ''}`} key={`prd-tab-${ind}`}>
                <a
                  className="nav-link contentPrimary-imp p-14-16"
                  href={hrefVal}
                  id={relevantId === 'haircut' ? 'hair' : relevantId === 'spray_tan' ? 'beauty' : relevantId}
                  onClick={() => {
                    this.setState({ hashValue: hrefVal });
                  }}
                >
                  {get(prd, 'title', '')}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  responsiveHead() {
    if (this.state.width <= 800) {
      return (
        <>
          <AppBar position="fixed" className={this.state.scrolledDown ? 'light-header' : 'light-header shadow-none-imp'}>
            <Toolbar className="height-44 p-14-16 align-items-center justify-content-spaced">
              <div />
              <AddressStep
                assignToCart={this.props.assignToCart}
                toggleAuth={() => this.setState({ showLogin: true })}
                showLogin={this.state.showLogin}
                changeBackground={this.props.changeBackground}
                setLoaderFlag={this.props.setLoaderFlag}
                jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
              />
              <div>
                <Badge overlap="rectangular" color="secondary" className="size-12-20-imp" badgeContent={this.cartItemsCount()} invisible={this.hasCartItems()}>
                  <button
                    type="button"
                    className="btn btn-link contentPrimary prl-0"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  >
                    <ShoppingCart />
                  </button>
                </Badge>
              </div>
            </Toolbar>
          </AppBar>
          {this.scrollableTabs()}
        </>
      );
    }
    return (
      <div className="grd-bg-gray height-320 pl-64 relative">
        <img className="floatR" src={sideImg} alt="" />
        <div className="ht-100-70 d-flex-only align-items-center pt-80 pb-45">
          <AddressStep
            assignToCart={this.props.assignToCart}
            toggleAuth={() => this.setState({ showLogin: true })}
            showLogin={this.state.showLogin}
            changeBackground={this.props.changeBackground}
            setLoaderFlag={this.props.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
          />
        </div>
        {this.scrollableTabs()}
      </div>
      // TODO revisit logic of login for event booking and disable editing address in this view 
      // handle going back in the flow 
    );
  }

  mobileSize() {
    const emptyCart = this.hasCartItems();
    if (this.state.width <= 800) {
      return (
        <>
          {this.responsiveHead()}
          <ServicesList
            scrolledDown={this.state.scrolledDown}
            updateHash={(hashValue) => {
              this.setState({ hashValue });
            }}
            assignToCart={this.props.assignToCart}
            setLoaderFlag={this.props.setLoaderFlag}
            displayError={this.props.displayError}
            showCart={() => this.setState({ open: true })}
            jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
            next={this.nextStep}
            hashValue={this.state.hashValue}
          />
          {this.state.open ? (
            <CartDrawer
              emCrt={emptyCart}
              open={this.state.open}
              close={() => this.setState({ open: false })}
              displayError={this.props.displayError}
              next={this.nextStep}
              assignToCart={this.props.assignToCart}
              jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
            />
          ) : null}
        </>
      );
    }
    return (
      <div className={emptyCart ? '' : 'row'}>
        <div className={emptyCart ? '' : 'col-sm-9 br-r-opaque pr-0'}>
          {this.responsiveHead()}
          <div className={emptyCart ? 'maxW-75-vw sm-w-100' : ''}>
            <ServicesList
              scrolledDown={this.state.scrolledDown}
              updateHash={(hashValue) => {
                this.setState({ hashValue });
              }}
              assignToCart={this.props.assignToCart}
              setLoaderFlag={this.props.setLoaderFlag}
              displayError={this.props.displayError}
              showCart={() => this.setState({ open: true })}
              jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
              next={this.nextStep}
              hashValue={this.state.hashValue}
            />
          </div>
        </div>
        {emptyCart ? null : (
          <div className="col-sm-3 pl-0 z-index-2">
            <CartDrawer
              emCrt={emptyCart}
              displayError={this.props.displayError}
              next={this.nextStep}
              assignToCart={this.props.assignToCart}
              jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
            />
          </div>
        )}
      </div>
    );
  }

  addressLogic() {
    const address = get(this.props, 'booking.cart.address', null);
    if (!isEmpty(address)) {
      if (!address.id) {
        createAddress(address, (response) => {
          const addressId = get(response, 'data.address.id', null);
          this.cartLogic(addressId);
        }, () => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
      } else {
        this.cartLogic(address.id);
      }
    } else {
      this.setState({ showLogin: false });
    }
  }

  cartLogic(addressId) {
    const cartId = get(this.props, 'booking.cart.id', '');
    if (!cartId && addressId) {
      createCart(addressId, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
        this.props.assignToCart({ cart: decode(response2.data.cart), addressId });
        this.cartProductLogic();
      }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
    } else if (cartId && addressId) {
      updateCart(cartId, { address_id: addressId }, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
        this.props.assignToCart({ cart: decode(response2.data.cart), addressId });
        this.cartProductLogic();
      }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
    } else {
      this.setState({ showLogin: false });
    }
  }

  cartProductLogic() {
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    const anonCPs = get(this.props, 'anonymousCart.cartProducts', []);
    const cartId = get(this.props, 'booking.cart.id', '');
    if (!isEmpty(anonCPs)) {
      const sz = size(anonCPs);
      this.props.setLoaderFlag(true);
      map(anonCPs, (cpp, ind) => {
        addCartProduct(cartId, {
          product_id: get(this.props, 'booking.product.id'),
          client_name: cpp.clientName,
        }, (resp) => {
          this.props.assignToCart({ cart: decode(resp.data.cart) });
          const newCartProducts = get(this.props, 'booking.cart.cartProducts', []);
          let relevantCartProduct = last(newCartProducts);
          if (ind + 1 !== sz) {
            relevantCartProduct = head(newCartProducts);
            // since the max cps to add is 2, so this check is
            // to prevent messing-up the ids by async calls
          }
          updateCartProduct(cartId, get(relevantCartProduct, 'id', ''), {
            option_ids: cpp.optionIds,
          }, get(this.props, 'fieldsHolder.csrfToken', ''), (resp_2) => {
            this.props.assignToCart({ cart: decode(resp_2.data.cart) });
            const cp = get(this.props, `booking.cart.cartProducts.${ind}`, {});
            seBookingDetails(decode(resp_2.data.cart), cp, get(this.props, 'booking.product.title', '').toLowerCase(), this.props.client.first_time_booker, 'booking_23_variant', sz, get(cp, 'subtitle', ''), get(this.props, 'client.loggedIn', false), get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""), isEventBooking());

            if (sz === ind + 1) {
              this.props.setAnonymousCart({});
              this.props.setAvailableTherapists([]);
              this.props.setLoaderFlag(false);
              this.goToTimingStep();
            }
          }, (err_2) => {
            this.props.setLoaderFlag(false);
            this.props.displayError(get(err_2, 'response.data.errors.0.message', defaultErrorMessage));
          });
        }, (err_1) => {
          this.props.setLoaderFlag(false);
          this.props.displayError(get(err_1, 'response.data.errors.0.message', defaultErrorMessage));
        });
      });
    } else if (!isEmpty(cartProducts)) {
      this.goToTimingStep();
    } else {
      this.setState({ showLogin: false });
    }
  }

  authCallBack() {
    if (isEventBooking()) {
      this.props.setLoaderFlag(false);
      if (!get(this.props, 'booking.cart.address.complete', false)) {
        this.setState({ showLogin: false, showFinishAddress: true });
      } else {
        this.goToTimingStep();
      }
    } else {
      this.addressLogic();
    }
  }

  authModal() {
    if (this.state.showLogin) {
      return (
        <GenericAuthModal
          cart_id={get(this.props, 'booking.cart.id', '') && !isEventBooking()}
          next={this.authCallBack}
          closeModal={() => this.setState({ showLogin: false })}
          isSignin
          jul_2024_booking_enhancements={get(this.props, 'jul_2024_booking_enhancements', '')}
        />
      );
    }
    return null;
  }
  finishAddressModal() {
    if (this.state.showFinishAddress) {
      return (
        <FinishAddressStep
          hideBackArrow={true}
          closeDrawer={(val) => {
            this.setState({ showFinishAddress: false });
            if (val) {
              this.goToTimingStep();
            }
          }}
          assignToCart={this.props.assignToCart}
          displayError={this.props.displayError}
        />
      );
    }
    return null;
  }

  nextStep() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    this.setState({ open: false });
    if (!loggedIn) {
      this.setState({ showLogin: true });
    } else if (isEventBooking() && this.hasCartItems()) {
      this.authCallBack();
    } else if (this.hasCartItems()) { /* empty */ } else {
      this.addressLogic();
    }
  }

  cartItemsCount() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    const anonCPs = get(this.props, 'anonymousCart.cartProducts', []);
    if (loggedIn) {
      return size(cartProducts);
    }
    return size(anonCPs);
  }

  hasCartItems() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    const anonCPs = get(this.props, 'anonymousCart.cartProducts', []);
    if (loggedIn) {
      return isEmpty(cartProducts);
    }
    return isEmpty(anonCPs);
  }

  render() {
    return (
      <>
        {this.mobileSize()}
        {this.authModal()}
        {this.finishAddressModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.addresses,
  booking: state.booking,
  products: Object.values(get(state, 'productsReducer.products', {})),
  client: state.client,
  bookingFlow: state.bookingFlow,
  anonymousCart: state.anonymousCart,
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {
  setCart, setAddresses, setProducts, setAvailableTherapists, setAnonymousCart,
})(Index);
